import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`ThinkPads med Linux, i3-gaps, och Alex gör en Nuke 'n Pave. Detta och massa mer gött i veckans avsnitt av Trevlig Mjukvara.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Red Hat + Lenovo: `}<a parentName="li" {...{
          "href": "https://www.forbes.com/sites/jasonevangelho/2020/09/01/red-hat-and-lenovo-thinkpad-linux-fedora-laptop-os/#54edfecb4127"
        }}>{`https://www.forbes.com/sites/jasonevangelho/2020/09/01/red-hat-and-lenovo-thinkpad-linux-fedora-laptop-os/#54edfecb4127`}</a><ul parentName="li">
          <li parentName="ul">{`Nu kan man (typ) köpa ThinkPad med Linux: `}<a parentName="li" {...{
              "href": "https://www.lenovo.com/us/en/laptops/thinkpad/thinkpad-x1/X1-Carbon-Gen-8-/p/22TP2X1X1C8"
            }}>{`https://www.lenovo.com/us/en/laptops/thinkpad/thinkpad-x1/X1-Carbon-Gen-8-/p/22TP2X1X1C8`}</a></li>
        </ul></li>
      <li parentName="ul">{`WSL 2, updatering: `}<a parentName="li" {...{
          "href": "https://www.bleepingcomputer.com/news/microsoft/windows-subsystem-for-linux-is-getting-these-useful-new-features/"
        }}>{`https://www.bleepingcomputer.com/news/microsoft/windows-subsystem-for-linux-is-getting-these-useful-new-features/`}</a></li>
      <li parentName="ul">{`Ny typ av tangentbord?: `}<a parentName="li" {...{
          "href": "https://blog.system76.com/post/625078388641710080/reimagining-the-keyboard"
        }}>{`https://blog.system76.com/post/625078388641710080/reimagining-the-keyboard`}</a></li>
      <li parentName="ul">{`Blender 2.90 (tack Christer): `}<a parentName="li" {...{
          "href": "https://www.blender.org/download/releases/2-90/"
        }}>{`https://www.blender.org/download/releases/2-90/`}</a></li>
      <li parentName="ul">{`PipeWire: `}<a parentName="li" {...{
          "href": "https://blogs.gnome.org/uraeus/2020/09/04/pipewire-late-summer-update-2020/"
        }}>{`https://blogs.gnome.org/uraeus/2020/09/04/pipewire-late-summer-update-2020/`}</a></li>
      <li parentName="ul">{`2021 blir allt bättre igen (tack Frontend-Micke): `}<a parentName="li" {...{
          "href": "https://www.theverge.com/2020/8/17/21372487/microsoft-internet-explorer-11-support-end-365-legacy-edge"
        }}>{`https://www.theverge.com/2020/8/17/21372487/microsoft-internet-explorer-11-support-end-365-legacy-edge`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`BankID, Swish, och Facebook i samhället: `}<a parentName="li" {...{
          "href": "https://mastodon.online/web/statuses/104788370870341682"
        }}>{`https://mastodon.online/web/statuses/104788370870341682`}</a>{` `}<a parentName="li" {...{
          "href": "https://idiomdrottning.org/bankid-stanger-dorrar/"
        }}>{`https://idiomdrottning.org/bankid-stanger-dorrar/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`i3-gaps: `}<a parentName="li" {...{
          "href": "https://github.com/Airblader/i3"
        }}>{`https://github.com/Airblader/i3`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex Rust-äventyr`}</h3>
    <ul>
      <li parentName="ul">{`Myrsteg men ändå framsteg`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      